import React, { useRef } from "react";
import { Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import { primaryColor } from "../../constant";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EastIcon from "@mui/icons-material/East";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rating from '@mui/material/Rating';


export default function Testimonials() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const sliderRef = useRef(null);

    const styles = {
        container: {
            background: "#F7F8F9",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            flexDirection: "column",
        },
        heading: {
            fontSize: isMobile ? 22 : "45px",
            color: "#000",
            textAlign: "left",
            margin: "3% 0 0",
            padding: 0,
        },
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 150,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const testimonials = [
        {
            name: "David Stark",
            title: 'Extra Fast Service With An Awesome Customer Service Support',
            review: "I was very happy with their customer service support, they are very polite and give you a better advise if you are going in the wrong direction. Hasham is one from management, and he'll never let you feel that you are talking to him for the first time.",
            starRating: 5,
        },
        {
            name: "Digital Nomad",
            title: '100% recommended for International Founders!',
            review: "100% recommended for international founders, who are struggling in putting things together from LLC formation to securing EIN and filing BOI reporting everything handled smoothly & professionally!",
            starRating: 5,
        },
        {
            name: "Ramsha Khan",
            title: 'It was a pleasant experience working..',
            review: "It was a pleasant experience working with their team. They have completed my process professionally and swiftly in Wyoming. I am very satisfied with their services. Highly recommended from my end.",
            starRating: 5,
        },
        {
            name: "Cleverpoly",
            title: 'It was very easy to work with them',
            review: "It was very easy to work with them, very professional and cooperating team. my account took some time due to holidays but it's active now!!! thanks so much",
            starRating: 5,
        },
        {
            name: "Abdullah Khan",
            title: 'The team was accommodating in answering',
            review: "The team was accommodating in answering all of my queries regarding C-Corporation. I registered my Corporation through them in Texas, and the process was smooth and fast. I would recommend them to anyone registering their company in the US.",
            starRating: 5,
        },
    ];

    function showSlider() {
        return testimonials.map((item, index) => {
            return (
                <div style={{ padding: 0 }} key={index}>
                    <div style={{ padding: isMobile ? "40px" : "60px 40px", background: "#F7F8F9", borderRadius: 20 }}>
                        {/* <img src="/images/star.png" style={{ width: 100 }} alt="star" /> */}
                        <Rating name="read-only" value={5} readOnly />
                        <h4 style={{ color: "black", fontSize: 21, fontWeight: 600 }}>{item?.title}</h4>
                        <p style={{ fontSize: 16, fontWeight: 400, opacity: "70%", margin: "2% 0 8%", color: "black" }}>
                            {item?.review}
                        </p>
                        <p style={{ color: "black", fontSize: 18, fontWeight: 550 }}>{item?.name}</p>
                    </div>
                </div>
            );
        });
    }

    return (
        <>
            <Grid container spacing={4} style={{ padding: isMobile ? "17% 5% 3%" : "10% 10% 0" }}>
                <Grid item md={6} style={{ width: "100%", position: "relative" }}>

                    <img loading='lazy' src="/images/Vector 1.svg" alt="Vector Image" style={{
                        position: 'absolute',
                        left: isMobile ? '-2%' : '0',
                        top: isMobile ? '-20%' : '-10%',
                        width: isMobile ? '90%' : '100%',
                    }} />

                    <div style={{ margin: "1% 0 0", padding: isMobile ? "0" : "0 10%" }}>
                        <Slider ref={sliderRef} {...settings}>
                            {showSlider()}
                        </Slider>
                    </div>
                </Grid>
                <Grid item md={6} style={{ width: "100%" }}>
                    <div>
                        <p style={{ color: primaryColor, fontSize: 16, borderLeft: `1px solid ${primaryColor}` }}>
                            &nbsp; TESTIMONIALS
                        </p>
                        <h3 style={styles.heading}>
                            What our customers say
                            <img src="/images/text-high.svg" alt="highlight" style={{ width: isMobile ? 40 : '' }} />
                        </h3>
                        <img src="/images/underline.svg" style={{ width: isMobile ? 200 : 300 }} alt="underline" />
                        <p style={{ color: "#40483D", fontSize: 14, width: "fit-content" }}>
                            Experience the difference with our trusted services. Hear directly from those who have transformed their ideas into reality with our expert guidance. <br />
                            From seamless business setups to navigating complex regulations, our clients value the personalized support and unmatched expertise we bring to the table.
                        </p>
                    </div>

                    <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button
                            startIcon={<KeyboardBackspaceIcon />}
                            style={{
                                textTransform: "capitalize",
                                background: "gray",
                                color: "white",
                                margin: "44px 0px 44px",
                                borderRadius: "50px",
                                padding: "1% 3%",
                            }}
                            onClick={() => sliderRef.current.slickPrev()} // Trigger the previous slide
                        >
                            Previous
                        </Button>
                        <Button
                            endIcon={<EastIcon />}
                            style={{
                                textTransform: "capitalize",
                                background: "#EA2024",
                                color: "white",
                                margin: "44px 0px 44px auto",
                                borderRadius: "50px",
                                padding: "1% 3%",
                            }}
                            onClick={() => sliderRef.current.slickNext()} // Trigger the next slide
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
